import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

import './photos.scss';

const PhotoPage = ({data}) => (
  <Layout>
    <SEO title="Photo Gallery" />
    <h1>Photo Gallery</h1>
    <p>If you have photos you would like added to the gallery, please let us know!</p>
    <ol>
      {
        data.allPhotos.edges.map((photo, index) => (
          <li key={index}>
            <a href={photo.node.childImageSharp.original.src} target="_blank" rel="nofollow noreferrer">
              <img src={photo.node.childImageSharp.fluid.src} />
            </a>
          </li>
        ))
      }
    </ol>
  </Layout>
);

export const query = graphql `query {
  allPhotos: allFile(
    filter: {
      extension: {in: ["png", "jpg"]}
      sourceInstanceName: {eq: "images"}
      relativeDirectory: {eq: "photos"}
    }
    sort: {
      fields: name
      order: ASC
    }
  ) {
    edges {
      node {
        childImageSharp {
          fluid (
            maxHeight: 180,
            maxWidth: 240,
            cropFocus: ATTENTION
          ){
            ...GatsbyImageSharpFluid
          }
          original {
            src
          }
        }
      }
    }
  }
}`; 

export default PhotoPage;
